<template>

  <el-dialog
      title="新增"
      v-model="performanceinfo"
      width="30%"
      center append-to-body @close='closeDialog'>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
      <el-form-item label="绩效名称" prop="name" :rules="[
      { required: true, message: '请输入名称', trigger: 'blur' },
    ]"
      >
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="绩效状态" prop="status" :rules="[
      { required: true, message: '请选择状态', trigger: 'blur' },
    ]"
      >
          <el-radio-group  v-model="ruleForm.status">
            <el-radio :label="1"  border>开启</el-radio>
            <el-radio :label="0" border>禁用</el-radio>
          </el-radio-group>
      </el-form-item>
    </el-form>
      <div class="ucForm-group-ad">
        <div class="ucForm-group ucBtn-bg">
          <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
          <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog"><i
              class="iImg"></i>返回</button>
        </div>
      </div>
      <div class="clear"></div>

  </el-dialog>

</template>

<script>
export default {
  created () {
    this.performanceinfo = true;
    this.clearinfo();
  },

  data(){
    let self=this;
    return{
      ruleForm:{
        id:'',
        status:'',
        name:'',
      },

      }},

  methods: {

    commit:function(ruleForm){

      this.$refs[ruleForm].validate((valid) => {

        if (valid) {
          var self = this;
          var data = {};
          data.cmd='insertLevelInfo';
          data.name=this.ruleForm.name;
          data.status=this.ruleForm.status;
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("修改成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
        } else {
          console.log('error submit!!');
          return false;
        }
      });



      /*this.errors = [];
      var self = this;
      var data = {};
      data.id=this.id;
      if(this.ruleForm.nam==null||this.ruleForm.nam==''){
          return false;
      }
      else{
        data.name=this.ruleForm.name;
      }
      if(this.ruleForm.status==null||this.ruleForm.status==''){
        return false;
      }
      else{
        data.status=this.ruleForm.status;
      }

      this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
        self.$message("修改成功");
        self.closeDialog();
      }, function(data) {
        self.$message(data.reason);
      }, self);*/
    },
    closeDialog: function(){
      this.performanceinfo = false;
      this.clearinfo();
      this.$emit('closeClass'); //通知父组件改变。
    },
    clearinfo:function(){
      this.description = "";
    },

  },


  mounted(){

  },
  components: {

  }
}
</script>

<style scoped>

</style>